<template>
  <div class="m-auto text-center text-white md:w-3/6 h-screen">
    <img
      src="@/assets/security-icon.svg"
      alt="Security icon"
      class="m-auto mt-24"
    />
    <div v-if="step === 1">
      <h1 class="mt-5 mb-2 font-semibold text-xl">FORGOT PASSWORD?</h1>
      <p class="md:w-3/6 m-auto">
        Enter your registered email below to receive password reset instructions
      </p>
      <EmailForm @resetPassword="HANDLE" :userEmail="email"/>
    </div>
    <div v-if="step === 2">
      <CodeForm @validateCode="HANDLE" />
    </div>
    <div v-if="step === 3">
      <NewPasswordForm :token="token" @submitNewPassword="HANDLE" />
    </div>
    <div v-if="showErrorMessage" class="text-center text-danger mb-5">
      <em class="fa-solid fa-circle-exclamation"></em>
      <span class="ml-1">
        {{ errorMessage }}
      </span>
    </div>
    <router-link class="underline mt-12" :to="{ name: 'Login' }"
      >Back to login</router-link
    >
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import EmailForm from "@/components/auth/forgotPassword/EmailForm.vue";
import CodeForm from "@/components/auth/forgotPassword/CodeForm.vue";
import NewPasswordForm from "@/components/auth/forgotPassword/NewPasswordForm.vue";
import {
  passwordResetCodeValidation,
  passwordResetRequest,
  resetPassword,
} from "@/services/auth/password.js";

const step = ref(1);
const token = ref(null);
const showErrorMessage = ref(false);
const errorMessage = ref(null);
const email = ref(null)

const router = useRouter();
const route = useRoute();

onMounted(() => {
  email.value = route.query.email;
});

async function HANDLE(param) {
  try {
    switch (step.value) {
      case 1:
        await passwordResetRequest(param);
        break;
      case 2:
        await passwordResetCodeValidation(param);
        token.value = param;
        break;
      case 3:
        await resetPassword(param, token.value);
        router.push({ name: "Login" });
    }

    step.value++;
    showErrorMessage.value = false;
  } catch (error) {
    console.log(error.message);
    showError(error.message);
  }
}

function showError(errorMess) {
  showErrorMessage.value = true;
  errorMessage.value = errorMess;
}
</script>
