import request from "@/Rest";
import { AUTH_URLS } from "@/Rest/api/urls";

export async function changePassword(oldPassword, newPassword, userId) {
  const credentials = `${oldPassword}:${newPassword}`;
  const credentialsB64 = btoa(`${credentials}`);

  try {
    const { data } = await request.PATCH(
      `${AUTH_URLS.changePassword}${userId}/`,
      {},
      {
        headers: {
          "Change-Pass-Data": credentialsB64,
        },
      }
    );

    return { data, success: true };
  } catch (error) {
    if (error.response.data.current_password.includes("Wrong password")) {
      return { wrongPassword: true, success: false };
    }
    return { success: false };
  }
}

export async function resetPassword(password, token) {
  const credentialsB64 = btoa(`:${password}`);

  try {
    const { data } = await request.POST(
      AUTH_URLS.resetPasswordChange,
      { token },
      {
        headers: {
          Authorization: `Basic ${credentialsB64}`,
        },
      }
    );

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function passwordResetCodeValidation(token) {
  try {
    await request.POST(AUTH_URLS.resetPasswordValidate, {
      token,
    });
  } catch (error) {
    throw new Error("Code is invalid");
  }
}

export async function passwordResetRequest(email) {
  try {
    await request.POST(AUTH_URLS.resetPassword, {
      email,
    });
  } catch (error) {
    throw new Error("Something went wrong please validate the data");
  }
}
